// import './App.css';
// import Home from "./pages/Home";
import offlinesvg from "./images/offlinesvg.svg";
import React, { useEffect, useState, startTransition, lazy, Suspense } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import {  toast } from "react-toastify";
import loadingg from "./images/loadinggif.gif";


const Home = lazy(() => import("./pages/Home"));
const Collegedetails = lazy(() => import("./pages/Collegedetails"));
const About = lazy(() => import("./pages/About"));
const Contactus = lazy(() => import("./pages/Contactus"));
const Searchresult = lazy(() => import("./pages/Searchresult"));
const Navbar = lazy(() => import("./components/Navbar"));
const Course = lazy(() => import("./pages/Course"));
const Facnav = lazy(() => import("./components/Facnav"));
const Studentlogin = lazy(() => import("./pages/Studentlogin"));
const Joberole = lazy(() => import("./pages/Jobrole"));
const Jobdesc = lazy(() => import("./pages/Jobdesc"));
const Facultyreg = lazy(() => import("./pages/Facultyreg"));
const Searchrole = lazy(() => import("./pages/Searchrole"));
const Facforgotpass = lazy(() => import("./pages/Facforgotpass"));
const Facotp = lazy(() => import("./pages/Facotp"));
const Facupdpass = lazy(() => import("./pages/Facupdpass"));
const Gallery = lazy(() => import("./pages/Gallery"));
const Studentsignup = lazy(() => import("./pages/Studentsignup"));
const Termsandconditions = lazy(() => import("./pages/Termsandconditions"));
const Forgetpass = lazy(() => import("./pages/Forgetpass"));
const Sendotp = lazy(() => import("./pages/Sendotp"));
const Createnewpass = lazy(() => import("./pages/Createnewpass"));

function App() {
  const location = useLocation();
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const toastid = "stoptoast"
  const navigate=useNavigate()
  const retryConnection = () => {
    if (navigator.onLine) {
      // toast.dismiss(toastid);
      // toast.success("You are back online!", { toastId: toastid });
      setIsOnline(true);
    } else {
      // toast.dismiss(toastid);

      // toast.error("Still offline. Please check your connection.", { toastId: toastid });
    }
  };
  

  useEffect(() => {
    const handleOnline = () => {
      startTransition(() => setIsOnline(true));
      // toast.dismiss(toastid);
      // toast.success("You are online",{toastId:toastid});
      // location.reload();
    };

    const handleOffline = () => {
      startTransition(() => setIsOnline(false));
    
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  const showFacultyNav = ["/Searchrole", "/Jobdesc"].includes(location.pathname);

  useEffect(()=>
  {
     async function fun()
     {
       try {
        const res=await localStorage.getItem("userRole")
        if(res)
        {
          navigate("/Searchrole")
        }
       } catch (error) {
        console.log(error)
       }
     }
     fun()
  },[])
  return (
    <div className="App">
      {isOnline ? (
      <Suspense
        fallback={
          <div className="w-full h-screen">
          <img
            className=" mx-auto my-[28%] "
            src={loadingg}
            alt="loading..."
            // style={{ marginTop: "20%", marginLeft: "40%" }}
            />
            </div>
        }
      >
        {showFacultyNav ? <Facnav /> : <Navbar />}
        
        
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Searchresult" element={<Searchresult />} />
            <Route path="/Collegedetails/:id" element={<Collegedetails />} />
            <Route path="/about" element={<About />} />
            <Route path="/Course" element={<Course />} />
            <Route path="/contactus" element={<Contactus />} />
            <Route path="/studentlogin" element={<Studentlogin />} />
            <Route path="/Studentsignup" element={<Studentsignup />} />
            <Route path="/Facultyreg" element={<Facultyreg />} />
            <Route path="/Jobrole" element={<Joberole />} />
            <Route path="/Searchrole" element={<Searchrole />} />
            <Route path="/Jobdesc" element={<Jobdesc />} />
            <Route path="/Facforgotpass" element={<Facforgotpass />} />
            <Route path="/Facotp" element={<Facotp />} />
            <Route path="/Facupdpass" element={<Facupdpass />} />
            <Route path="/Gallery" element={<Gallery />} />
            <Route path="/termsandconditions" element={<Termsandconditions />} />
            <Route path="/Forgetpass" element={<Forgetpass />} />
            <Route path="/Sendotp" element={<Sendotp />} />
            <Route path="/Createnewpass" element={<Createnewpass />} />
          </Routes>
      </Suspense>
        ) : (
          <div
            className="offline"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              marginBlock: "22vh",
            }}
          >
            <h1 className="text-3xl font-semibold">You Are Offline</h1>
            <button className="px-4 py-2 mt-4 bg-[#008E97] text-white rounded hover:bg-[#2d767c]" onClick={retryConnection}>Retry</button>
          </div>
        )}
    </div>
  );
}

export default App;